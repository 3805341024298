import React, { useState } from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { BsArrowRight } from "react-icons/bs";

import flagUK from "../../static/img/flag-uk.svg";
import flagNA from "../../static/img/region-flag-na.svg";

import "../styles/styles.scss";


import GENERAL_CONFIGS from "../../content/pages/general.json";

const Footer = () => {
  	return (
	  <StaticQuery
		query={graphql`
		  query {
			allMdx(
			  sort: { fields: [frontmatter___date], order: DESC }
			  filter: { frontmatter: { posttype: { eq: "brands" } } }
			) {
			  edges {
				node {
				  excerpt
				  fields {
					slug
				  }
				  frontmatter {
					title
				  }
				}
			  }
			}
		  }
		`}
		render={(data) => {
		  
		  let allBrands = data.allMdx.edges;
          if (GENERAL_CONFIGS.header_navigation_brands) {
            allBrands = allBrands.filter(({ node }) =>
              GENERAL_CONFIGS.footer_navigation_brands.includes(
                node.frontmatter.title
              )
            );
          }
          
          allBrands = allBrands.sort(function(a, b) {
			return (
			  GENERAL_CONFIGS.footer_navigation_brands.indexOf(
				a.node.frontmatter.title
			  ) -
			  GENERAL_CONFIGS.footer_navigation_brands.indexOf(
				b.node.frontmatter.title
			  )
			);
		  });

		  
		  let brandsCol1 = allBrands.slice(0, 4);
		  let brandsCol2 = allBrands.slice(4, 8);

		  return (
			<>
			  <footer>
				<div className="container">
				  <div className="columns is-mobile is-multiline">
					<div className="column is-4-tablet is-6-mobile is-4-desktop is-4-widescreen is-4-fullhd links">
					  <ul>
						<li>
						  <Link to="/company">About</Link>
						</li>
						{/* <li>
						  <Link to="/team">Team</Link>
						</li> */}

						<li>
						  <Link to="/news">News</Link>
						</li>

						<li>
						  <Link to="/careers">Careers</Link>
						</li>

						<li>
						  <Link to="/faq">FAQ</Link>
						</li>

						<li>
						  <Link to="/contact">Contact</Link>
						</li>
					  </ul>
					</div>

					<div className="column is-4-tablet is-6-mobile is-4-desktop is-4-widescreen is-4-fullhd links">
					  <ul>
						<li className="our-brands">OUR BRANDS </li>
					
						{brandsCol1
						  .map(({ node }) => {
							return (
							  <li>
							  	<Link to={`/brands${node.fields.slug}`}>{node.frontmatter.title}</Link>
							  </li>
							);
						  })}
					  </ul>
					  
					  {brandsCol1.length < 4 &&
					  	<li>
						  <Link to="/brands">
							View all brands <BsArrowRight />
						  </Link>
						</li>
					  }
					  
					  
					</div>

					<div className="column is-4-tablet is-6-mobile is-4-desktop is-4-widescreen is-4-fullhd links">
					  <ul>
						{brandsCol2
						  .map(({ node }) => {
							return (
							  <li>
								<Link to={`/brands${node.fields.slug}`}>{node.frontmatter.title}</Link>
							  </li>
							);
						  })}

						{brandsCol2.length > 0 &&
					  	<li>
						  <Link to="/brands">
							View all brands <BsArrowRight />
						  </Link>
						</li>
					  }
					  </ul>
					</div>
				  </div>

				  <div className="columns bottom-links is-vcentered">
					<div className="column is-narrow">© 2022 Upper Canada Soap</div>

					<div className="column right-links">
					  <div className="right-links-right">
						<div className="last">
						  <Link to="/terms-of-use">Terms of use</Link>
						</div>
						<div>
						  <Link to="/privacy-policy">Privacy policy</Link>
						</div>
					  </div>

					  <div className="flag-links">
						<div>
						  <span>
							<img src={flagNA} alt="" />
							<a href="https://uppercanadasoap.com/">NA website</a>
							<svg
							  width="12"
							  height="11"
							  fill="none"
							  xmlns="http://www.w3.org/2000/svg"
							>
							  <path
								stroke="#2E2E2E"
								d="m3.09 7.882 5.657-5.657M8.365 2.473l.608-.088-.149.668"
							  />
							  <path
								d="M4.68 2.048c.884.884 4.243.353 4.243.353M9.1 6.467c-.884-.884-.144-4.099-.144-4.099"
								stroke="#2E2E2E"
							  />
							</svg>
						  </span>
						</div>
						<div>
						  <span>
							<img src={flagUK} alt="" />
							<a href="https://uppercanadauk.co.uk/">UK website</a>
							<svg
							  width="12"
							  height="11"
							  fill="none"
							  xmlns="http://www.w3.org/2000/svg"
							>
							  <path
								stroke="#2E2E2E"
								d="m3.09 7.882 5.657-5.657M8.365 2.473l.608-.088-.149.668"
							  />
							  <path
								d="M4.68 2.048c.884.884 4.243.353 4.243.353M9.1 6.467c-.884-.884-.144-4.099-.144-4.099"
								stroke="#2E2E2E"
							  />
							</svg>
						  </span>
						</div>
						
					  </div>
					  
					</div>
				  </div>
				</div>
			  </footer>
			</>
		  );
		}}
	  />
	);
};

export default Footer;